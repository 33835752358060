import React from 'react'
import { Grid } from '@material-ui/core'
import { FormatQuote, Star } from '@material-ui/icons'
import Amoore from '../../images/a.moore.png'
import Slaramee from '../../images/s.laramee.png'
import Stremblay from '../../images/s.tremblay.png'
import styled from 'styled-components'

const ColoredStar = styled(Star)`
    color: #ffd500;
`

const TestimonyWrapper = styled(Grid)`
    padding: 50px 0;
    color: white;
`

const TestimoniesWrapper = styled(Grid)`
    max-width: 1160px;
    margin: -150px auto 0;
    @media (orientation: portrait) {
        margin: 0 auto;
    }
`

const Testimony = ({ name, text, imgSrc }) => {
    return (
        <TestimonyWrapper item container xs={12} md={4}>
            <Grid item xs={2}>
                <FormatQuote style={{ fontSize: '3.2rem' }} />
            </Grid>
            <Grid item xs={10} container>
                <Grid item xs={12}>
                    {text}
                </Grid>
                <Grid item xs={5} style={{ marginTop: '20px' }}>
                    <img src={imgSrc} alt={`portrait d'une personne`} />
                </Grid>
                <Grid item xs={7} style={{ marginTop: '45px' }}>
                    <span style={{ fontSize: '16px', fontWeight: 600 }}>
                        {name}
                    </span>
                    <br />
                    <ColoredStar style={{ marginLeft: '-5px' }} />
                    <ColoredStar />
                    <ColoredStar />
                    <ColoredStar />
                    <ColoredStar />
                </Grid>
            </Grid>
        </TestimonyWrapper>
    )
}

const Testimonies = () => {
    return (
        <TestimoniesWrapper container>
            <Testimony
                name="A. Moore"
                text="Merci pour l’excellent service. J’avais vite besoin d’un avocat pour régler un cas urgent dans mon entreprise et l’avocat qui m’a contacté est vraiment sympathique et compétent. Je ferai encore appel à ses services au besoin."
                imgSrc={Amoore}
            />
            <Testimony
                name="S. Laramée"
                text="Super service, simple et efficace. J’ai vécu une séparation dernièrement et mon avocat a bien su m’aider à gérer tous les enjeux, tant au niveau des finances que de la garde des enfants. Ça m’a enlevé un gros poids de l’avoir à mes côtés pour ces questions difficiles."
                imgSrc={Slaramee}
            />
            <Testimony
                name="S. Tremblay"
                text="Votre service est vraiment pertinent. J’ai été agréablement surpris par sa rapidité et son efficacité et j’ai trouvé le bon avocat pour mes besoins. Je le recommanderai c’est sûr si quelqu’un de mon entourage recherche un avocat."
                imgSrc={Stremblay}
            />
        </TestimoniesWrapper>
    )
}

export default Testimonies
