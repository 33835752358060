import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Main from '../../styles/LandingPages'
import { Button, Grid } from '@material-ui/core'
import Form from '../../../../src/containers/LandingForm'
import Testimonies from '../../components/Testimony'
import Badges from '../../components/Badges'

import LeFrisé from '../../images/le-frise.png'
import Arrow from '../../images/arrow.png'

const ComparerAvocatsRapidement = (props) => {
    const [showForm, setShowForm] = useState(false)
    return (
        <Layout
            location={props.location}
            lowFootprint={true}
            noPhones={true}
            canonical={props.canonical}
            noindex={props.noindex}
        >
            <Main>
                <div className="background-container">
                    <div className="form-container">
                        <div className="form alternate">
                            <Grid container spacing={2}>
                                {!showForm && (
                                    <div className="intro">
                                        <Grid item xs={12}>
                                            <h1>
                                                Trouvez Rapidement un{' '}
                                                <strong>
                                                    Avocat Compétent
                                                </strong>{' '}
                                                pour vos Affaires Personnelles
                                                ou d’Entreprise
                                            </h1>
                                            <p>
                                                Répondez à quelques questions
                                                seulement et nous vous
                                                référerons le meilleur avocat de
                                                votre région selon vos besoins
                                                spécifiques.
                                            </p>
                                            <div className="cta">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        setShowForm(true)
                                                    }
                                                >
                                                    {props.cta
                                                        ? props.cta
                                                        : 'TROUVER MON AVOCAT'}
                                                </Button>
                                                <img
                                                    src={Arrow}
                                                    alt="fleche vers le bouton"
                                                />
                                            </div>
                                            <Badges />
                                        </Grid>
                                    </div>
                                )}
                                {showForm && (
                                    <Form
                                        noSteps={true}
                                        alternate={true}
                                        source="AVO2"
                                    />
                                )}
                            </Grid>

                            <img
                                className={`${
                                    showForm ? 'frisou hide-mobile' : 'frisou'
                                }`}
                                src={LeFrisé}
                            />
                        </div>
                    </div>
                </div>
                <Testimonies />
            </Main>
        </Layout>
    )
}

export default ComparerAvocatsRapidement
