import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import Service from '../../images/service.png'
import Strategy from '../../images/strategy.png'
import Expertise from '../../images/expertise.png'

const BadgesGrid = styled(Grid)`
    max-width: ${(props) => (props.big ? '960px' : '450px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
    margin-right: auto;
    margin-left: auto;
    padding: 20px 20px 50px;
    img {
        display: block;
        margin: 0 auto;
        width: ${(props) => (props.big ? '160px' : '100px')};
    }
    span {
        color: #006fbf;
        display: block;
        font-size: ${(props) => (props.big ? '24px' : '16px')};
        font-weight: 600;
        padding: 10px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
    }
    @media (max-width: 667px) {
        padding: 30px 0;
        margin-top: 0px;
        img {
            width: 60px;
        }
        span {
            font-size: 16px;
            padding: 10px 0 0;
        }
        width: 100%;
        margin: initial;
    }
`

const Badges = (props) => {
    return (
        <BadgesGrid container spacing={2} {...props}>
            <Grid item xs={4}>
                <img src={Expertise} alt="étampe expertise" />
                <span>Expertise</span>
            </Grid>
            <Grid item xs={4}>
                <img src={Strategy} alt="étampe stratégie" />
                <span>Stratégie</span>
            </Grid>
            <Grid item xs={4}>
                <img src={Service} alt="étampe service" />
                <span>Service</span>
            </Grid>
        </BadgesGrid>
    )
}

export default Badges
